import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {REQUESTER_APP_HEADER_NAME, REQUESTER_APP_HEADER_VALUE} from '@constants/http.constants';
import {PlatformType} from '@interfaces/common/http.interface';

@Injectable()
export class ApiGatewayInterceptor implements HttpInterceptor {
  constructor(@Inject(REQUESTER_APP_HEADER_VALUE) private requesterAppHeader: PlatformType,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let updatedRequest = request.clone({
      setHeaders: {
        [REQUESTER_APP_HEADER_NAME]: this.requesterAppHeader
      },
    });

    return next.handle(updatedRequest);
  }
}

