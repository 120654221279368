import {inject, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from "@environments/environment";
import {isPlatformBrowser} from "@angular/common";
import {REDIRECT} from "@constants/session-storage.constants";
import {DEFAULT_HOME_PATH, DEFAULT_LOGIN_PATH} from '@constants/http.constants';
import {EPrevError, IEnvironmentPlatforms, IUserPlatform} from '@interfaces/user/user-common.interface';
import {UserService} from '@services/integrations/user/user.service';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {UserManagerService} from '@services/user/user-manager.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {REDIRECT_PARAMETER} from '@constants/query-strings.constants';
import {HttpParams} from '@angular/common/http';
import {SSORedirectUrlParams} from '@interfaces/common/http.interface';
import {UrlUtils} from '@utilities/url.utils';

export interface IRedirectActions {
  subscription?: { planId: number | string, coupon?: string }
}

export interface IAppRedirect {
  platform?: string,
  subDomain?: string,
  route: string,
  actions?: IRedirectActions
}

@Injectable({
  providedIn: 'root'
})
export class AppRedirectsService {
  router  = inject(Router);
  userService = inject(UserService);
  uiLoaderService = inject(UiLoaderService);
  userManagerService = inject(UserManagerService);
  connectorV2Service = inject(ConnectorV2Service);

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }

  redirectToSSO(ssoRoute?: string, redirectParams?: SSORedirectUrlParams) {
    let baseUrl = environment.platforms.sso.link;
    const parameters = new HttpParams({fromObject: {
        [REDIRECT_PARAMETER]: location.href,
        ...redirectParams,
    }});
    const url = `${baseUrl}/${ssoRoute ?? DEFAULT_LOGIN_PATH}?${parameters.toString()}`;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }

  redirectToPlatform(redirectInfo: { platform?: string, subDomain?: string, route?: string, actions?: IRedirectActions }) {
    let baseUrl = this.getPlatformLink(redirectInfo.platform, redirectInfo.subDomain);
    let fullUrl = `${baseUrl}${redirectInfo.route}`;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = fullUrl;
    }
  }

  redirectToAnonymous(route?: string) {
    window.location.replace(`${environment.platforms.anonymous.link}${route ?? '/home'}`)
  }

  getRedirectParamsFromSessionStorage(): string | undefined {
    if (window.sessionStorage) {
      let sessionString = sessionStorage.getItem(REDIRECT);
      if (!sessionString) return undefined;
      return this.validatedUrl(sessionString);
    }
  }

  validatedUrl(url: string) {
    const baseDomain = environment.platforms.base.link;
    try {
      const URLObject = new URL(url);
      if (URLObject.hostname.endsWith(baseDomain)) {
        return url;
      }
    } catch (e) {
      console.error('Error While Retrieving Redirect URL', e);
      return;
    }
  }

  saveRedirectUrl(route: ActivatedRoute) {
    const redirectURLParameter = route.snapshot.queryParams[REDIRECT_PARAMETER];

    if (!redirectURLParameter) return;

    const redirectUrl = this.validatedUrl(redirectURLParameter);
    if (redirectUrl) {
      sessionStorage.setItem(REDIRECT, redirectUrl);
    }
  }

  private getPlatformLink(platform?: string, subDomain?: string): string {
    let platformLink = environment.platforms.b2c.link;
    if (platform) {
      switch (platform.toLowerCase()) {
        case environment.platforms.b2c.code.toLowerCase():
          platformLink = environment.platforms.b2c.link;
          break;
        case environment.platforms.dashboard.code.toLowerCase():
          platformLink = environment.platforms.dashboard.link;
          break;
        case environment.platforms.admin.code.toLowerCase():
          platformLink = environment.platforms.admin.link;
          break;
        case environment.platforms.payment.code.toLowerCase():
          platformLink = environment.platforms.payment.link;
          break;
        case environment.platforms.ld.code.toLowerCase():
          platformLink = environment.platforms.ld.link;
          break;
        default:
          if (platform.split('.').length > 1) {
            platformLink = platform;
          } else {
            platformLink = subDomain ? `https://${subDomain}.${environment.platforms.base.link}` : `https://${platform}.${environment.platforms.base.link}`;
          }
          break;
      }
    } else if (subDomain) {
      platformLink = `https://${subDomain}.${environment.platforms.base.link}`;
    }
    return platformLink;
  }

  navigateToPlatform(subdomain?: string) {
    this.uiLoaderService.startUiLoader('authorize-loader');
    let redirectUrl = this.getRedirectParamsFromSessionStorage();
    this.userService.getUserPlatforms().then(userPlatforms => {
      const redirectSubdomain = UrlUtils.getSubDomain(redirectUrl)
      if (userPlatforms.some(p => p.domain === redirectSubdomain)) {
        // user has access to the redirected platform
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else if (subdomain) {
          this.redirectToPlatform({subDomain: subdomain, route: '/'});
        } else {
          this.redirectToPlatform({route: '/'});
        }
      } else if (userPlatforms.length) {
        // user has access to another platforms
        const B2COrB2BUserPlatform = this.userManagerService.getB2COrB2BUserPlatform(userPlatforms, redirectSubdomain);
        if (B2COrB2BUserPlatform) { // redirect to relative B2C or B2B platform
          this.redirectToAnotherPlatform(B2COrB2BUserPlatform, UrlUtils.getURLPathWithQueryParameters(redirectUrl));
        } else if (userPlatforms.length === 1) { // redirect to only available platform
          this.redirectToAnotherPlatform(userPlatforms[0]);
        } else { // let user decide which platform to redirect to
          this.router.navigate(['/redirect-center'], {queryParams: {'prevError': EPrevError.NoAccess}}).then(() => {
            this.uiLoaderService.stopAllUiLoader();
            this.connectorV2Service.isCheckingAuthorization = false;
          });
        }
      } else {
        // user has no access to any  platform
        this.router.navigate(['/redirect-center'], {queryParams: {'prevError': EPrevError.Blocked}}).then(() => {
          this.uiLoaderService.stopAllUiLoader();
          this.connectorV2Service.isCheckingAuthorization = false;
        });
      }
    }).catch((error => {
      //TODO ask business if it suitable to logout user here
      //TODO user has no access to all platforms
    })).finally(() => {
      this.uiLoaderService.stopUiLoader('authorize-loader');
      this.connectorV2Service.isCheckingAuthorization = false;
    });
  }

  redirectToAnotherPlatform(platform: IUserPlatform, path?: string) {
    let platformUrl;
    let baseUrl = environment.platforms.base.link;
    const standardPlatform = Object.values<IEnvironmentPlatforms>(environment.platforms).find(p => p.code === platform.platform);
    if (standardPlatform) {
      platformUrl = `${standardPlatform.link}`;
    } else {
      platformUrl = `https://${platform.domain}.${baseUrl}`;
    }
    window.location.replace(platformUrl + (path || ''));
  }
}
