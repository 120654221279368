export interface IFawryResponse {
  trn: string;
  referenceNumber: string;
}

export interface IPayfortResponse {
  trn: string;
  _3dURL: string;
  is3d: boolean;
}

export interface IPaypalResponse {
  trn: string;
}

export enum PaymentMethods {
  PAYPAL = 1,
  PAYFORT,
  FREE,
  FAWRY,
  MOBILE_WALLET,
  OTHERS,
  MADA,
  CHECKOUT,
  PAYMOB,
  APPLE_PAY = 11
}

export type IPaymentMethod = keyof typeof PaymentMethods;
