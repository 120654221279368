export interface IUserInfo {
  uuid: string;
  name: string;
  email: string;
  interests: boolean,
  hasPurchasedCourse: boolean;
  subscribed: boolean;
  isMobileVerified: boolean;
  subscriptionStatus: SubscriptionStatus;
  renewalDate: string;
  isMentor: boolean;
  userType: IUserType;
  cuuid: string;
  hasDemographicData: boolean;
  loginCountDown: number;
  firstAuthAfterSubscription: boolean;
  loginType: BELoginTypes
  phoneNumber: string;
}

export interface PhoneSignupUserInfo {
  userName: string;
  email: string;
}

export enum IUserType {
  B2C=1,
  B2B,
  FACEBOOK,
  GOOGLE
}

export enum BELoginTypes {
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
}

export enum ELoginTypes {
  EMAIL = 'Email',
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
  PHONE = 'Phone'
}

export enum SubscriptionStatus
{
  UnSubscribed = 1,
  Subscribed = 2,
  GracePeriod = 3,
  Expired = 4
}

export enum UserTypes {
  Anonymous = 'Anonymous',
  Consumer = 'Consumer',
  Business = 'Business',
}
