import {inject, Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {IEmailSignup} from '@interfaces/common/auth.interface';
import {
  IActivationResponse,
  IChangePasswordPayload,
  IFacebookLoginInfoResponse, IFacebookLoginResponse, IGoogleLoginResponse,
  ILoginResponse, INewLoginResponse, ISignupResponse, RefreshTokenRequest, RefreshTokenResponse
} from '@interfaces/user/user-common.interface';
import {IDevice} from '@interfaces/common/device.interface';
import {IUserInfo, PhoneSignupUserInfo} from '@interfaces/authorized-user/user.interface';
import {IAuthorizedResponse} from "@services/integrations/auth-v2/auth-v2.service";
import {Observable} from 'rxjs';
import {HttpService} from '@services/http-service/http.service';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpV2Service {
  private httpService = inject(HttpService);
  private customHttpService = inject(CustomHttpService);

  constructor() {
  }

  emailSingUp(sender: string, signUpForm: IEmailSignup): Promise<ISignupResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/signup',
      sender: sender,
      receiver: 'signup',
      body: signUpForm,
    });
  }

  activateUser(sender: string, v: number, g: string): Promise<IActivationResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/signup-activate',
      sender: sender,
      receiver: 'active',
      body: {v: v, g: g},
    });
  }

  resendEmail(sender: string, email: string, password: string, redirectUrl: string): Promise<any> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/resend-activation-mail',
      sender: sender,
      receiver: 'mail',
      body: {email, password, redirectUrl},
    });
  }

  b2cEmailLogin(sender: string, email: string, password: string, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/login',
      sender: sender,
      receiver: 'login',
      body: {email: email, password: password, platform: platform, subDomain: subDomain},
    });
  }

  forgetPassword(email: string): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/send-forget-password-mail',
      sender: 'forget-password',
      receiver: 'send-forget-password-mail',
      body: {
        email: email
      },
    });
  }

  resetPassword(resetPasswordBody: { a: string, s: string, newPassword: string }): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/save-forget-password',
      sender: 'reset-password',
      receiver: 'save-forget-password',
      body: resetPasswordBody,
    });
  }

  getFacebookInfo(sender: string, accessToken: string): Promise<IFacebookLoginInfoResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/user-fb-info',
      sender: sender,
      receiver: 'user-fb-info',
      body: {accessToken: accessToken},
    });
  }

  facebookLogin(sender: string, facebookInfo: IFacebookLoginInfoResponse, platform: string | null, subDomain: string | null): Promise<IFacebookLoginResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/facebooklogin',
      sender: sender,
      receiver: 'facebooklogin',
      body: {platform: platform, ...facebookInfo},
    });
  }

  googleLogin(sender: string, accessToken: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/googlelogin',
      sender: sender,
      receiver: 'googlelogin',
      body: {accessToken: accessToken, platform: platform},
    });
  }

  googleLoginWithCode(sender: string, code: string, redirectUrl: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/googlelogin-byCode',
      sender: sender,
      receiver: 'googlelogin-byCode',
      body: {code: code, redirectUrl: redirectUrl, platform: platform, subDomain: subDomain},
    });
  }

  getUserDevices(userInfo: { email: string, password: string, userType: number } | {}): Promise<IDevice[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/user-devices',
      sender: 'devices',
      receiver: 'user-devices',
      body: userInfo,
    });
  }

  sendPinCode(sender: string, sendPinCodeObject: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/send-pin-code',
      sender: sender,
      receiver: 'send-pin-code',
      body: sendPinCodeObject,
    })
  }

  verifyEmailPinCode(sender: string, activationObject: {
    pinCode: number,
    userData?: { email?: string, password?: string, userType: number, accessToken?: string } | {}
  }): Promise<{ isValid: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/verify-pin-code',
      sender: sender,
      receiver: 'verify-pin-code',
      body: activationObject,
    });
  }

  removeUserDevice(removeDevice: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/remove-device',
      sender: 'devices',
      receiver: 'remove-device',
      body: removeDevice,
    });
  }

  authorize(sender: string, platform: string|null, subDomain: string|null): Promise<IAuthorizedResponse> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/authorize/api/sso/authorize',
      sender: sender,
      receiver: 'authorize',
      body: {platform: platform, subDomain: subDomain},
    });
  }

  addNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/add-notification-token',
      sender: sender,
      receiver: 'add-notification-token',
      body: {notificationToken: token},
    });
  }

  removeNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/remove-notification-token',
      sender: sender,
      receiver: 'add-notification-token',
      body: {notificationToken: token},
    });
  }

  logoutUser(sender: string, notificationToken: string | null): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/logout',
      sender: sender,
      receiver: 'logout',
      body: {deviceId: notificationToken},
    });
  }

  getUserInfo(sender: string): Promise<IUserInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/GetUserInfo',
      sender: sender,
      receiver: 'logout',
      body: {},
    });
  }

  setUserInfo(userInfo: PhoneSignupUserInfo): Promise<void> {
    return this.customHttpService.sendPutRequest({
      endpoint: 'new-orchestrator/SSO/api/users/profile',
      body: userInfo,
    });
  }

  changeUserPassword(sender: string, payload: IChangePasswordPayload): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/change/password',
      sender: sender,
      receiver: 'changePassword',
      body: payload
    });
  }

  accounts_loginFeatureCheck(email: string): Observable<boolean> {
    return this.httpService.get<boolean>(
      'accounts/api/feature/login',
      {
        queryParams: new HttpParams().set('email', email),
      }
    );
  }

  accounts_refreshToken(body: RefreshTokenRequest): Observable<RefreshTokenResponse> {
    return this.httpService.post<RefreshTokenResponse>(
      'accounts/api/token/refresh',
      body,
    );
  }

  accounts_logUserOut(): Observable<boolean> {
    return this.httpService.post(
      'accounts/api/Users/logout',
      null,
    );
  }
}
