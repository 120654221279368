import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RequestOptions} from '@interfaces/common/http.interface';
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }


  post<T, B = object>(endpoint: string, body: B, requestOptions?: RequestOptions): Observable<T> {
    const apiUrl = `${environment.apiGatewayUrl}/${endpoint}`;
    return this.httpClient.post<T>(
      apiUrl,
      body,
      {
        withCredentials: true
      }
    );
  }

  get<T>(endpoint: string, requestOptions: RequestOptions): Observable<T> {
    if (requestOptions.queryParams) {
      endpoint += `?${requestOptions.queryParams.toString()}`
    }
    const apiUrl = `${environment.apiGatewayUrl}/${endpoint}`;
    return this.httpClient.get<T>(
      apiUrl,
      {
        withCredentials: true
      }
    );
  }
}
