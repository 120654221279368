import {IPaymentMethod} from "@interfaces/common/payment.interface";
import {ELoginTypes, UserTypes} from "@interfaces/authorized-user/user.interface";
import {CourseProducerType} from "@interfaces/course/course.interface";
import {IAssignedByAdmin, IChangeID} from "@interfaces/learning-goals/learning-goals.interface";
import {LanguageName} from "@interfaces/common/language.interface";
import {EnvironmentNames} from "@interfaces/environment/environment.interface";
import {SearchResultType} from "@interfaces/search/search.interface";

export interface TrackingSuperProperties {
  readonly platform: UserClientType;
  user_id: string; // user Email or login Id
  language: LanguageName;
  user_country: string;
  user_type: UserTypes;
  subscription_type?: string;

  // set hashed name and mail for DataHash to use them for PII
  // TODO remove these properties when fully upgrading the GTM client to server
  th_capi_fn: string;
  th_capi_em: string;
}

export interface TrackingMetaData {
  readonly device_id: string;
  readonly environment: EnvironmentNames;
  user_uuid: string;
}

export enum SearchType {
  SEARCH_PAR = 'search bar',
  SEARCH_RESULTS = 'search results',
}

export type TrackingPaymentStatus = 'Success' | 'Pending' | 'Failed';

export interface TrackingEventsProperties extends TrackingSuperProperties, TrackingMetaData {
  user_name: string;
  course_name: string;
  course_id: string;
  company_name: string;
  company_id: number;
  assigned_skills_count: number;
  assigned_by_admin: IAssignedByAdmin;
  streaks: number;
  change_id: IChangeID;
  registration_date: string;

  current_url_path: string;
  current_url_protocol: string;
  current_url_search: string;
  source_page_type: pageType;
  source_page_name: string;
  source_page_url: string;

  registration_method: ELoginTypes;
  activation_date: string;
  login_method: ELoginTypes;
  user_organization?: string;
  last_login_date: string;
  failure_reason: string;
  selected_subscription_plan_type: string;
  selected_subscription_plan_price: number;
  selected_subscription_plan_currency: string;
  available_subscription_types: string[];
  selected_payment_method: IPaymentMethod;
  available_payment_methods: IPaymentMethod[];
  subscription_id?: number;
  selected_subscription_plan_id: string;
  payment_status: TrackingPaymentStatus;
  coupon_code: string;
  coupon_code_accepted: boolean;
  coupon_code_failure_reason?: string;
  coupon_code_added: boolean;

  course_type: CourseProducerType;
  course_category: string;
  instructor_name: string;
  course_duration: number; // in minutes
  paid_course: boolean;
  arabic_course: boolean;
  save_date: string;
  number_of_saved_courses: number;
  enrollment_date: string;
  number_of_enrollments: number;
  lesson_name: string;
  lesson_duration: number; // in minutes
  lesson_number: string;
  number_of_played_free_lessons: number;

  search_type: SearchType;
  search_keyword: string;
  number_of_results: number;
  result_type: SearchResultType;
  result_position: number;

}

type NoProperties = never;

export interface TrackingEvents {
  page_view: EventProperties<
    | 'current_url_path'
    | 'current_url_protocol'
    | 'current_url_search'>;

  // Sign-Up / Login
  user_sign_up_started: EventProperties<
    | 'source_page_type'
    | 'source_page_name'
    | 'source_page_url'>;
  user_sign_up_completed: EventProperties<
    | 'user_id'
    | 'registration_date'
    | 'registration_method'>;
  user_account_activated: EventProperties<NoProperties>;
  user_sign_up_failed: EventProperties<
    |'registration_method'
    | 'failure_reason'>;
  user_login_completed: EventProperties<
    | 'login_method'
    | 'source_page_type'
    | 'source_page_name'
    | 'source_page_url'
    | 'user_organization'
    | 'last_login_date'
    | 'user_type'>;
  user_login_failed: EventProperties<
    | 'login_method'
    | 'source_page_type'
    | 'source_page_name'
    | 'source_page_url'
    | 'failure_reason'>;

  user_details: EventProperties<
    | 'user_uuid'
    | 'user_id'
    | 'user_name'
    | 'subscription_id'
    | 'subscription_type'>;

  // Subscription
  plans_page_viewed: EventProperties<
    | 'source_page_type'
    | 'source_page_name'
    | 'source_page_url'>;
  subscription_plan_selected: EventProperties<
    | 'selected_subscription_plan_type'
    | 'selected_subscription_plan_price'
    | 'selected_subscription_plan_currency'
    | 'selected_subscription_plan_id'
    | 'available_subscription_types'>;
  payment_method_selected: EventProperties<
    | 'selected_subscription_plan_type'
    | 'selected_subscription_plan_price'
    | 'selected_subscription_plan_currency'
    | 'selected_subscription_plan_id'
    | 'available_subscription_types'
    | 'available_payment_methods'
    | 'selected_payment_method'>;
  coupon_code_submitted: EventProperties<
    | 'selected_subscription_plan_type'
    | 'selected_subscription_plan_price'
    | 'selected_subscription_plan_currency'
    | 'selected_subscription_plan_id'
    | 'coupon_code'
    | 'coupon_code_accepted'
    | 'coupon_code_failure_reason'>;
  subscription_checkout: EventProperties<
    | 'selected_subscription_plan_type'
    | 'selected_subscription_plan_price'
    | 'selected_subscription_plan_currency'
    | 'selected_subscription_plan_id'
    | 'coupon_code_added'
    | 'coupon_code'
    | 'selected_payment_method'>;
  payment_completed: EventProperties<
    | 'selected_subscription_plan_type'
    | 'selected_subscription_plan_price'
    | 'selected_subscription_plan_currency'
    | 'selected_subscription_plan_id'
    | 'coupon_code_added'
    | 'coupon_code'
    | 'selected_payment_method'
    | 'payment_status'>;

  //content_discovery
  course_page_viewed: EventProperties<
    | 'source_page_type'
    | 'source_page_name'
    | 'source_page_url'
    | 'course_name'
    | 'course_type'
    | 'course_category'
    | 'course_id'
    | 'instructor_name'
    | 'course_duration'
    | 'paid_course'
    | 'arabic_course'>;
  course_saved: EventProperties<
    | 'source_page_type'
    | 'source_page_name'
    | 'source_page_url'
    | 'course_name'
    | 'course_type'
    | 'course_category'
    | 'course_id'
    | 'instructor_name'
    | 'course_duration'
    | 'paid_course'
    | 'arabic_course'
    | 'save_date'>;
  course_enrollment: EventProperties<
    | 'course_name'
    | 'course_type'
    | 'course_category'
    | 'course_id'
    | 'instructor_name'
    | 'course_duration'
    | 'paid_course'
    | 'arabic_course'
    | 'enrollment_date'>;
  free_lesson_played: EventProperties<
    | 'course_name'
    | 'course_type'
    | 'course_category'
    | 'course_id'
    | 'instructor_name'
    | 'course_duration'
    | 'paid_course'
    | 'arabic_course'
    | 'lesson_name'
    | 'lesson_duration'
    | 'lesson_number'>;

  //LMSEvents
  certificate_added_to_linkedin: EventProperties<'course_name' | 'course_id'>;
  progress_tracker_loaded: EventProperties<
    'company_name'
    | 'company_id'
    | 'assigned_skills_count'
    | 'assigned_by_admin'
    | 'streaks'
  >,
  progress_tracker_goal_changed: EventProperties<
    'company_name'
    | 'company_id'
    | 'assigned_skills_count'
    | 'change_id'
  >;

  // Search
  search_initiated: EventProperties<
    | 'search_type'
    | 'search_keyword'
    | 'result_type'
    | 'number_of_results'>;
  search_result_clicked: EventProperties<
    | 'search_type'
    | 'search_keyword'
    | 'result_type'
    | 'result_position'
    | 'number_of_results'>;
}

export interface UserProperties extends noopUserProperties {
  user_sign_up_completed: EventProperties<
    | 'registration_date'
    | 'registration_method'>;
  user_account_activated: EventProperties<
    | 'user_id'
    | 'activation_date'>;
  user_login_completed: EventProperties<
    | 'user_organization'
    | 'last_login_date'>;
  course_saved: EventProperties<'number_of_saved_courses'>;
  course_enrollment: EventProperties<'number_of_enrollments'>;
  free_lesson_played: EventProperties<'number_of_played_free_lessons'>;
}

type noopUserProperties = {
  [K in keyof TrackingEvents]: unknown;
}

export type EventProperty<T extends keyof TrackingEvents> = TrackingEvents[T];

export type UserProperty<T extends keyof UserProperties> = UserProperties[T];


type EventProperties<K extends keyof TrackingEventsProperties> = Pick<TrackingEventsProperties, K>

export enum ClientName {
  DataClient = 'data-client',
  GA4Client = 'GA4-client'
}

export enum UserClientType {
  WEBSITE = 'Web',
  ANDROID = 'Android',
  IOS = 'iOS',
}

export interface TrackingEventProperties<T extends keyof TrackingEvents> {
  properties:  EventProperty<T> & Partial<TrackingSuperProperties>;
  userProperties?: UserProperty<T>,
  metadata: Partial<TrackingMetaData>;
  clientName: ClientName;
}

export interface TrackingOptions<T extends keyof TrackingEvents> extends TrackingEventData<T>{
  metadata: Partial<TrackingMetaData>;
  clientName: ClientName;
}

export type EventUserProperties<T extends keyof TrackingEvents> =
  T extends never ? never :{
  set?: {
    [key in keyof Partial<UserProperty<T>>]: any
  };
  increment?: {
    [key in keyof Partial<UserProperty<T>>]: number
  };
}

export interface TrackingEventData<T extends keyof TrackingEvents> {
  event_properties: EventProperty<T>;
  user_properties?: EventUserProperties<T>;
}

export type pageType = 'Home'
  | 'Categories'
  | 'Courses'
  | 'Course Details'
  | 'New Courses'
  | 'Popular Courses'
  | 'Mentors'
  | 'Mentor Details'
  | 'Become an instructor'
  | 'Search'
  | 'Subscription Plans'
  | 'Signup'
  | 'Login'
  | 'Direct'
  | 'N/A';

export interface PageTypes {
  pattern: string;
  Type: pageType
}

export type SourcePageInfo = Pick<TrackingEventsProperties,
  'source_page_type'
  | 'source_page_name'
  | 'source_page_url'
>;

export enum SourceTypes {
  AUTHENTICATION_SOURCE,
  CURRENT_PAGE,
  PREVIOUS_PAGE,
}

export interface IGoogleTagManagerData<T> {
  eventName: string,
  eventData: T
}
