import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginAuthGuard} from "@guards/login-auth.guard";
import {addLanguageGuard, generateLanguageRoutes, getWildcardRoutes} from "@services/routing/routing-helper/routing-helper";
import {AVAILABLE_LANGUAGES} from "@constants/ui.constants";
import {DEFAULT_LOGIN_PATH, NOT_FOUND_PATH} from "@constants/http.constants";
import {CustomRoutes} from "@services/routing/routing-helper/routing.interface";

const routes: CustomRoutes = [
  {
    path: 'login',
    loadChildren: () => import('@login-modules/login/login.module')
      .then(mod => mod.LoginModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('@login-modules/signup/signup.module')
      .then(mod => mod.SignupModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'forget-password',
    loadChildren: () => import('@login-modules/forget-password/forget-password.module')
      .then(mod => mod.ForgetPasswordModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('@login-modules/reset-password/reset-password.module')
      .then(mod => mod.ResetPasswordModule),
    canActivate: [LoginAuthGuard]
  },
  {
    path: 'redirect-center',
    loadChildren: () => import('@login-modules/redirect-center/redirect-center.module')
      .then(mod => mod.RedirectCenterModule)
  },
  {
    path: NOT_FOUND_PATH,
    loadChildren: () => import('@login-modules/not-found/not-found.module')
      .then(mod => mod.NotFoundModule),
    canActivate: [LoginAuthGuard]
  },
];

addLanguageGuard(routes);
const languageRoutes: Routes = generateLanguageRoutes(AVAILABLE_LANGUAGES, routes);
const wildcardRoutes: Routes = getWildcardRoutes(DEFAULT_LOGIN_PATH, AVAILABLE_LANGUAGES);

@NgModule({
  imports: [
    RouterModule.forRoot([
      ...routes,
      ...languageRoutes,
      ...wildcardRoutes,
    ], {
    onSameUrlNavigation: 'ignore',
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
