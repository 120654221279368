import {ChildActivationEnd, Event, Routes} from "@angular/router";
import {LanguageRoutes} from "@guards/language-routes.guard";
import {ILanguage} from "@interfaces/common/language.interface";
import {NOT_FOUND_PATH} from "@constants/http.constants";
import {CustomRoutes, IRoutingData} from "@services/routing/routing-helper/routing.interface";

export const getWildcardRoutes = (defaultPath: string, languages: ILanguage[]): CustomRoutes => {
  const languageRoutes: CustomRoutes[] = languages.map<CustomRoutes>(language => {
    return [
      {
        path: language.code,
        pathMatch: 'full',
        redirectTo: `${language.code}/${defaultPath}`
      },
      {
        path: language.code, children: [
          {
            path: '**',
            redirectTo: `/${language.code}/${NOT_FOUND_PATH}`,
          },
        ]
      },
    ];
  });
  const mergedLanguageRoutes: CustomRoutes = languageRoutes.reduce<CustomRoutes>((acc, current) => {
    return acc.concat(current)
  }, []);
  return [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: `${defaultPath}`,
    },
    ...mergedLanguageRoutes,
    {
      path: '**',
      redirectTo: `/${NOT_FOUND_PATH}`,
    },
  ];
};

export function addLanguageGuard(routes: CustomRoutes): CustomRoutes {
  return routes.map(route => {
    if (route.canActivateChild) {
      route.canActivateChild.unshift(LanguageRoutes);
    } else {
      route.canActivateChild = [LanguageRoutes];
    }
    return route;
  });
}
export function generateLanguageRoutes(languages: ILanguage[], routes: CustomRoutes): CustomRoutes {
  let languageRoutes: Routes = [];
  languages.map(language => {
    const appendedRoutes: CustomRoutes = routes
      .map(route => {
        return {
          ...route,
          path: `${language.code}/${route.path}`,
        }
      });
    languageRoutes.push(...appendedRoutes);
  });
  return languageRoutes;
}

export function getAccumulatedRouteData(event: ChildActivationEnd): IRoutingData {
  let snapshot = event.snapshot;
  while (snapshot.firstChild !== null) {
    snapshot = snapshot.firstChild;
  }
  // get the data from the last child route
  // it will contain our custom data accumulated from all the parent routes
  return snapshot.data as IRoutingData;
}

