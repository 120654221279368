import {inject, Injectable} from '@angular/core';
import {AuthHttpV2Service} from '@services/integrations/auth-v2/auth-http-v2.service';
import {IEmailSignup} from '@interfaces/common/auth.interface';
import {
  IActivationResponse,
  IChangePasswordPayload,
  IFacebookLoginInfoResponse, IFacebookLoginResponse, IGoogleLoginResponse,
  INewLoginResponse, ISignupResponse
} from '@interfaces/user/user-common.interface';
import {IDevice} from '@interfaces/common/device.interface';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {SentryService} from "@services/sentry/sentry.service";
import {TrackingDataService} from "@services/tracking/tracking-data.service";
import {CookieService} from 'ngx-cookie';
import {baseLinkCookieOptions, JWT_ACCESS_TOKEN, JWT_REFRESH_TOKEN} from '@constants/cookies.constants';
import {catchError, from, Observable, of, take, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';

export interface IAuthorizedResponse {
  privilege: string,
  isLogged: boolean,
  forceLogout: boolean,
  isReachedMaxDevices: boolean,
  isUserBlockAll: boolean,
  platform: string,
  isFisrtLogin: boolean,
  firstAuthAfterSubscription: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AuthV2Service {
  private cookieService = inject(CookieService);
  private connectorV2Service = inject(ConnectorV2Service);

  constructor(private authV2Http: AuthHttpV2Service,
              private sentryService: SentryService,
              private trackingDataService: TrackingDataService,
              ) {
  }

  emailSingUp(sender: string, signUpForm: IEmailSignup): Promise<ISignupResponse> {
    return this.authV2Http.emailSingUp(sender, signUpForm);
  }

  activateUser(sender: string, v: number, g: string): Promise<IActivationResponse> {
    return this.authV2Http.activateUser(sender, v, g);
  }

  resendEmail(sender: string, email: string, password: string, redirectUrl: string): Promise<any> {
    return this.authV2Http.resendEmail(sender, email, password, redirectUrl);
  }

  emailLogin(sender: string, email: string, password: string, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    return this.authV2Http.b2cEmailLogin(sender, email, password, platform, subDomain);
  }

  forgetPassword(email: string): Promise<{ result: boolean }> {
    return this.authV2Http.forgetPassword(email);
  }

  resetPassword(resetPasswordBody: { a: string, s: string, newPassword: string }): Promise<{ result: boolean }> {
    return this.authV2Http.resetPassword(resetPasswordBody);
  }

  getFacebookInfo(sender: string, accessToken: string): Promise<IFacebookLoginInfoResponse> {
    return this.authV2Http.getFacebookInfo(sender, accessToken);
  }

  facebookLogin(sender: string, facebookInfo: IFacebookLoginInfoResponse, platform: string | null, subDomain: string | null): Promise<IFacebookLoginResponse> {
    return this.authV2Http.facebookLogin(sender, facebookInfo, platform, subDomain);
  }

  googleLogin(sender: string, accessToken: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    return this.authV2Http.googleLogin(sender, accessToken, platform, subDomain);
  }

  googleLoginWithCode(sender: string, code: string, redirectUrl: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    return this.authV2Http.googleLoginWithCode(sender, code, redirectUrl, platform, subDomain);
  }

  getUserDevices(userInfo: { email: string, password: string, userType: number } | {}): Promise<IDevice[]> {
    return this.authV2Http.getUserDevices(userInfo);
  }

  sendPinCode(sender: string, isResend: boolean, receiver?: string,
              userInfo?: { email?: string, password?: string, userType: number, accessToken?: string } | {}): Promise<{ result: boolean }> {
    let sendPinCodeObject: any = {
      isResend: isResend
    };
    if (userInfo) {
      sendPinCodeObject['userData'] = {...userInfo};
    } else {
      sendPinCodeObject['reciever'] = receiver;
    }
    return this.authV2Http.sendPinCode(sender, sendPinCodeObject);
  }

  verifyEmailPinCode(sender: string, activationCode: number,
                     userInfo?: { email?: string, password?: string, userType: number, accessToken?: string } | {}): Promise<{ isValid: boolean }> {
    let verifyCodeObject: {
      pinCode: number,
      userData?: { email?: string, password?: string, userType: number, accessToken?: string } | {}
    } = {
      pinCode: activationCode
    };
    if (userInfo) {
      verifyCodeObject['userData'] = {...userInfo};
    }
    return this.authV2Http.verifyEmailPinCode(sender, verifyCodeObject);
  }

  removeUserDevice(device: IDevice, userInfo?: { email: string, password: string }
    | { accessToken: string } | {}): Promise<{ result: boolean }> {
    let removeDeviceBody = {};
    if (userInfo) {
      removeDeviceBody = {
        deviceId: device.deviceId,
        ...userInfo
      };
    } else {
      removeDeviceBody = {
        deviceId: device.deviceId,
      };
    }
    return this.authV2Http.removeUserDevice(removeDeviceBody);
  }

  authorize(sender: string, platform: string | null, subDomain: string | null): Promise<IAuthorizedResponse> {
    return this.authV2Http.authorize(sender, platform, subDomain);
  }

  addNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.authV2Http.addNotificationToken(sender, token);
  }

  removeNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.authV2Http.removeNotificationToken(sender, token);
  }

  logUserOut() {
    const jwtToken = this.cookieService.get(JWT_ACCESS_TOKEN);
    let logoutRequest: Observable<boolean>;
    if (jwtToken) {
      logoutRequest = this.authV2Http.accounts_logUserOut();
    } else {
      logoutRequest = this.legacyUserLogout();
    }
    return logoutRequest.pipe(
      take(1),
      catchError( (error) => {
        this.accounts_resetUser();
        return of(false);
      }),
      tap(() => {
        this.accounts_resetUser();
      })
    );
  }

  getUserInfo(sender: string): Promise<IUserInfo> {
    return this.authV2Http.getUserInfo(sender);
  }

  changeUserPassword(sender: string, payload: IChangePasswordPayload): Promise<{ result: boolean }> {
    return this.authV2Http.changeUserPassword(sender, payload);
  }

  accounts_storeToken(tokenName: string, value: string): void {
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    this.cookieService.put(tokenName, value, {
      ...baseLinkCookieOptions,
      expires: cookieDate
    });
  }

  accounts_storeAccessToken(accessToken: string) {
    this.accounts_storeToken(JWT_ACCESS_TOKEN, accessToken);
  }

  accounts_storeRefreshToken(refreshToken: string) {
    this.accounts_storeToken(JWT_REFRESH_TOKEN, refreshToken);
  }

  accounts_resetUser() {
    this.sentryService.setUser(null);
    this.connectorV2Service.removeUser();
    this.accounts_removeAccessTokens();
  }

  accounts_removeAccessTokens(): void {
    this.cookieService.remove(JWT_ACCESS_TOKEN);
    this.cookieService.remove(JWT_REFRESH_TOKEN);
  }

  private legacyUserLogout(): Observable<boolean> {
    return from(this.authV2Http.logoutUser('auth-service', null)).pipe(map((response) => response.result));
  }
}
