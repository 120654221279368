import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, timer} from 'rxjs';
import {LanguageControlService} from "@services/language/language-control.service";
import {ILanguageCode} from "@interfaces/common/language.interface";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {DEFAULT_LANGUAGE_CODE} from "@constants/ui.constants";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageRoutes implements CanActivateChild {
  constructor(
    private router: Router,
    private languageControl: LanguageControlService,
    private connectorService: ConnectorV2Service,
    private translateService: TranslateService,
  ) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const availableLanguageCodes = this.languageControl.allLanguageCodes;
    const urlLanguageCode = this.getLanguageCodeFromUrl(state.url, availableLanguageCodes);

    if (urlLanguageCode) {
      return this.handleUrlWithLanguageCode(urlLanguageCode, state);
    }

    return this.handleUrlWithoutLanguageCode(state);
  }

  private handleUrlWithLanguageCode(urlLanguageCode: ILanguageCode, state: RouterStateSnapshot) {
    this.handleLanguageChange(urlLanguageCode);
    if (urlLanguageCode === DEFAULT_LANGUAGE_CODE) {
      const urlWithoutLanguageCode = state.url.split('/').slice(2).join('/');

      return timer(0).pipe(
        map(() => this.router.parseUrl(`/${urlWithoutLanguageCode}`))
      );
    }

    return true;
  }

  private handleUrlWithoutLanguageCode(state: RouterStateSnapshot) {
    const userCurrentLanguage = this.connectorService.connectorLanguage.getValue();

    this.handleLanguageChange(userCurrentLanguage);
    // check the user selected language, if same as default do nothing to the url
    if (userCurrentLanguage === DEFAULT_LANGUAGE_CODE) {
      return true;
    }

    // otherwise append the language code in the URL
    return this.router.parseUrl(`/${userCurrentLanguage}${state.url}`);
  }

  private handleLanguageChange(languageCode: ILanguageCode) {
    if (languageCode !== this.translateService.currentLang) {
      this.languageControl.changeLanguage(languageCode);
    }
  }

  private getLanguageCodeFromUrl(url: string, allowedLanguageCodes: ILanguageCode[]): ILanguageCode | undefined {
    const urlSegment = url.split('/');
    if (!urlSegment.length) return undefined;
    const firstRouteSegment = urlSegment[1];
    return allowedLanguageCodes.find((languageCode) => {
      return languageCode.toLowerCase() === firstRouteSegment.toLowerCase();
    });
  }
}
