import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from '@services/language/language-control.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'alm-root-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit, OnDestroy {

  @Output() formStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() verificationCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();
  optInputForm: UntypedFormGroup;
  focusDigits: boolean [] = [true, false, false, false];
  private digitsPattern: string = '^[0-9]*$';
  private subscriptions: Subscription [] = [];

  constructor(private fb: UntypedFormBuilder, private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    this.optInputForm = this.fb.group({
      firstDigit: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
      secondDigit: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
      thirdDigit: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
      fourthDigit: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
    });
  }

  ngOnInit(): void {
    let optInputSub = this.optInputForm.statusChanges.subscribe((status) => {
      if (status == 'VALID') {
        this.formStatus.emit(false);
        this.sendVerificationCode();
      } else {
        this.formStatus.emit(true);
      }
    });
    this.subscriptions.push(optInputSub);
  }

  getFormFieldsError(fieldControls: AbstractControl[]): number {
    for (let fieldControl of fieldControls) {
      if (fieldControl?.touched) {
        if (fieldControl?.errors?.required) {
          return 1;
        }
        if (fieldControl?.errors?.pattern?.requiredPattern == this.digitsPattern || fieldControl?.errors?.minlength
          || fieldControl?.errors?.maxLength) {
          return 2;
        }
      }
    }
    return 0;
  }

  sendVerificationCode(): void {
    let activationCode = this.optInputForm.controls['firstDigit'].value +
      this.optInputForm.controls['secondDigit'].value +
      this.optInputForm.controls['thirdDigit'].value +
      this.optInputForm.controls['fourthDigit'].value;
    this.verificationCode.emit(activationCode);
  }

  switchFocus(digit: number, forceFocus: boolean) {
    if (forceFocus) {
      this.forceFocus(digit);
    } else {
      if (digit == 1) {
        if (this.optInputForm.controls['firstDigit'].value.length == 1) {
          this.focusDigits[0] = false;
          this.focusDigits[1] = true;
        } else {
          this.focusDigits[0] = true;
        }
      }
      if (digit == 2) {
        if (this.optInputForm.controls['secondDigit'].value.length == 1) {
          this.focusDigits[1] = false;
          this.focusDigits[2] = true;
        } else {
          this.focusDigits[0] = true;
          this.focusDigits[1] = false;
        }
      }
      if (digit == 3) {
        if (this.optInputForm.controls['thirdDigit'].value.length == 1) {
          this.focusDigits[2] = false;
          this.focusDigits[3] = true;
        } else {
          this.focusDigits[1] = true;
          this.focusDigits[2] = false;
        }
      }
      if (digit == 4) {
        if (this.optInputForm.controls['fourthDigit'].value.length == 1) {
          this.focusDigits[3] = false;
        } else {
          this.focusDigits[2] = true;
          this.focusDigits[3] = false;
        }
      }
    }
  }

  forceFocus(digit: number): void {
    this.focusDigits.map((currentFocus, index) => {
      if (digit == index) {
        currentFocus = true;
      } else {
        currentFocus = false;
      }
    });
  }

  setCopiedValue($event: ClipboardEvent): void {
    this.optInputForm.controls['firstDigit'].setValue($event.clipboardData?.getData('text').charAt(0));
    this.optInputForm.controls['secondDigit'].setValue($event.clipboardData?.getData('text').charAt(1));
    this.optInputForm.controls['thirdDigit'].setValue($event.clipboardData?.getData('text').charAt(2));
    this.optInputForm.controls['fourthDigit'].setValue($event.clipboardData?.getData('text').charAt(3));
  }

  submitCode() {
    if (this.optInputForm.valid) {
      let activationCode = this.optInputForm.controls['firstDigit'].value +
        this.optInputForm.controls['secondDigit'].value +
        this.optInputForm.controls['thirdDigit'].value +
        this.optInputForm.controls['fourthDigit'].value;
      this.onSubmit.emit(activationCode);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    })
  }

}
