import {Component, DestroyRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LookupsService} from "@services/integrations/lookups/lookups.service";
import {ICountry} from "@interfaces/common/auth.interface";
import {MatSelectChange} from "@angular/material/select";
import {AbstractControl, UntypedFormControl, Validators} from "@angular/forms";
import {ReplaySubject, Subscription} from "rxjs";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {LanguageControlService} from "@services/language/language-control.service";
import ar from "./i18n/ar.json";
import en from "./i18n/en.json";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'alm-root-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private lookupService= inject(LookupsService);
  private uiLoader= inject(UiLoaderService);
  private languageControl= inject(LanguageControlService);

  @Input() required: boolean = true;
  @Input() inPaymentWebsite: boolean = false;
  @Input() phoneNumber?: string;
  @Input() placeholder: string = 'xx xxxx xxxx';
  @Output() onValidatePhone: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Input() countries: ICountry[] = [];
  @Input() selectedCountry?: ICountry;
  selectedCountryIndex: number = 0;

  phoneCtrlValidators = [
    Validators.minLength(8),
    Validators.maxLength(12)
  ]
  phoneCtrl: UntypedFormControl = new UntypedFormControl('', Validators.compose(this.phoneCtrlValidators));
  countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredCountries: ReplaySubject<ICountry[]> = new ReplaySubject<ICountry[]>(1);
  private phonePattern: string = '^[0-9]*$';
  maskPattern = {'0': {pattern: new RegExp('^[0-9]*$')}};
  dir: 'rtl' | 'ltr';
  private subscriptions: Subscription[] = [];


  constructor() {
    this.setupLanguageDirection(this.languageControl);
    this.registerTranslation();
  }

  async ngOnInit() {
    if (this.required) {
      this.phoneCtrl.setValidators([Validators.required, ...this.phoneCtrlValidators]);
    }
    await this.loadCountries();
    if (this.phoneNumber) {
      this.setPhoneNumber(this.phoneNumber);
    } else {
      await this.selectDefaultCountry();
    }

  }

  async loadCountries() {
    if (!this.countries.length) {
      this.countries = await this.lookupService.getAllCountriesByLanguageId('phone');
    }
    this.filteredCountries.next(this.countries);
  }

  async selectDefaultCountry() {
    if (this.selectedCountry) {
      const country = this.countries.find((country) => country.phoneCode === this.selectedCountry.phoneCode);
      this.setSelectedCountry(country);
    } else {
      try {
        const {phoneCode} = await this.lookupService.getPhoneCode('phone');
        const country = this.countries.find((country) => country.phoneCode === phoneCode);
        this.setSelectedCountry(country);
      } catch (e) {
        this.setSelectedCountry(this.countries[0]);
        console.error("Error getting user phone country code", e);
      }
    }
  }

  filterArrayByName(searchString: string, source: any[], filtered: ReplaySubject<any[]>) {
    filtered.next(source.filter((item) => item.name.toLowerCase().startsWith(searchString.toLowerCase())));
  }


  setPhoneNumber(phoneNumber: string) {
    this.uiLoader.stopUiLoader('countries-loader');
    const phoneCode = phoneNumber.split('-')[0];
    const mobileNumber = phoneNumber.split('-')[1];
    this.phoneCtrl.setValue(mobileNumber);
    const country = this.countries.find((country) => country.phoneCode == phoneCode.split('+')[1]);
    if (country) {
      this.setSelectedCountry(country);
    }
  }

  getSelectedIndex(country: ICountry): number {
    return this.countries.findIndex(c => c.code === country.code);
  }

  onCountrySelect($event: MatSelectChange) {
    let country = $event.value;
    this.setSelectedCountry(country);
    this.onPhoneChange();
  }

  onPhoneChange(){
    if(this.phoneCtrl.valid && this.phoneCtrl.value && this.selectedCountry){
      if(this.inPaymentWebsite) {
        this.onValidatePhone.emit(`+${this.selectedCountry.phoneCode}-${Number(this.phoneCtrl.value)}`);
      } else {
        this.onValidatePhone.emit(`+${this.selectedCountry.phoneCode}${Number(this.phoneCtrl.value)}`);
      }
    } else if (this.phoneCtrl.value) {
      this.onValidatePhone.emit(null);
    } else {
      this.onValidatePhone.emit(undefined);
    }
  }

  getFormFieldError(fieldControl: AbstractControl | null): number {
    if (fieldControl?.touched) {
      if (fieldControl?.errors?.required) {
        return 1;
      }
      if (fieldControl?.errors?.pattern?.requiredPattern != this.phonePattern || fieldControl?.errors?.minlength
        || fieldControl?.errors?.maxLength) {
        return 2;
      }
    }
    return 0;
  }

  private registerTranslation() {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  private setupLanguageDirection(languageControl: LanguageControlService) {
    languageControl.currentLanguage.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((lang) => {
      this.dir = lang.direction;
    });
  }

  private setSelectedCountry(country: ICountry) {
    this.selectedCountry = country;
    this.selectedCountryIndex = this.getSelectedIndex(country);
  }
}
