import {PageTypes} from "@services/tracking/tracking.interface";

export const pageTypes: PageTypes[] = [
  {
    pattern: '/courses$',
    Type: 'Courses'
  },
  {
    pattern: '/courses/new$',
    Type: 'New Courses'
  },
  {
    pattern: '/courses/popular$',
    Type: 'Popular Courses'
  },
  {
    pattern: '/courses/*',
    Type: 'Course Details'
  },
  {
    pattern: '/learning-programs$',
    Type: 'Learning Programs'
  },
  {
    pattern: '/bundles/*',
    Type: 'Learning Program Details'
  },
  {
    pattern: '/learning-programs/*',
    Type: 'Learning Program Details'
  },
  {
    pattern: '/(home)?$',
    Type: 'Home'
  },
  {
    pattern: '/categories/*',
    Type: 'Categories'
  },
  {
    pattern: '/mentors$',
    Type: 'Mentors'
  },
  {
    pattern: '/mentors/*',
    Type: 'Mentor Details'
  },
  {
    pattern: '/become-mentor$',
    Type: 'Become an instructor'
  },
  {
    pattern: '/search/*',
    Type: 'Search'
  },
  {
    pattern: '/plans$',
    Type: 'Subscription Plans'
  },
  {
    pattern: '/checkout$',
    Type: 'Subscription Plans'
  },
  {
    pattern: '/join$',
    Type: 'Signup'
  },
  {
    pattern: '/signup$',
    Type: 'Signup'
  },
  {
    pattern: '/login$',
    Type: 'Login'
  },

];
