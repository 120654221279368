import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, NavigationExtras, Router, RouterStateSnapshot} from '@angular/router';
import {timer} from 'rxjs';
import {LanguageControlService} from "@services/language/language-control.service";
import {ILanguageCode} from "@interfaces/common/language.interface";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {DEFAULT_LANGUAGE_CODE} from "@constants/ui.constants";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";


export const languageGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const languageControl = inject(LanguageControlService);

  const availableLanguageCodes = languageControl.allLanguageCodes;
  const urlLanguageCode = getLanguageCodeFromUrl(state.url, availableLanguageCodes);

  if (urlLanguageCode) {
    return handleUrlWithLanguageCode(urlLanguageCode, state);
  }

  return handleUrlWithoutLanguageCode(state);

}

function handleUrlWithLanguageCode(urlLanguageCode: ILanguageCode, state: RouterStateSnapshot) {
  handleLanguageChange(urlLanguageCode);
  if (urlLanguageCode === DEFAULT_LANGUAGE_CODE) {
    const urlWithoutLanguageCode = state.url.split('/').slice(2).join('/');

    const router = inject(Router);
    return timer(0).pipe(
      map(() => redirectWithExtras(router, `/${urlWithoutLanguageCode}`))
    );
  }

  return true;
}

function handleUrlWithoutLanguageCode(state: RouterStateSnapshot) {
  const connectorService = inject(ConnectorV2Service);
  const userCurrentLanguage = connectorService.connectorLanguage.getValue();

  handleLanguageChange(userCurrentLanguage);
  // check the user selected language, if same as default do nothing to the url
  if (userCurrentLanguage === DEFAULT_LANGUAGE_CODE) {
    return true;
  }

  // otherwise append the language code in the URL
  const router = inject(Router);
  return redirectWithExtras(router, `/${userCurrentLanguage}${state.url}`);
}

function handleLanguageChange(languageCode: ILanguageCode) {
  const languageControl = inject(LanguageControlService);
  const translateService = inject(TranslateService);

  if (languageCode !== translateService.currentLang) {
    languageControl.changeLanguage(languageCode);
  }
}

function getLanguageCodeFromUrl(url: string, allowedLanguageCodes: ILanguageCode[]): ILanguageCode | undefined {
  const urlSegment = url.split('/');
  if (!urlSegment.length) return undefined;
  const firstRouteSegment = urlSegment[1];
  return allowedLanguageCodes.find((languageCode) => {
    return languageCode.toLowerCase() === firstRouteSegment.toLowerCase();
  });
}

/**
 * Redirects the user to the provided path while preserving any extras from the current navigation.
 * If there are no extras, it will simply parse the URL and return it.
 * @param router The router instance.
 * @param path The path to redirect to.
 * @returns A URLTree or false if extras were found.
 */
function redirectWithExtras(router: Router, path: string) {
  const navigation = router.getCurrentNavigation();
  const urlPath = router.parseUrl(path);
  const pathname = path.split('?')[0];
  if (navigation?.extras) {
    router.navigate([pathname], {
      ...navigation?.extras,
      queryParams: urlPath.queryParams
    });
    return false;
  } else {
    return urlPath;
  }
}
