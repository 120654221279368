<div class="otp-input">
  <form action="" [formGroup]="optInputForm" (keyup.enter)="submitCode()">
    <div class="otp-input_fields" dir="ltr">
      <mat-form-field class="form-group" appearance="outline">
        <input [maxLength]="1" matInput type="text" name="phoneNumber"
               placeholder="_" [almMatInputAutofocus]="focusDigits[0]"
               (focusin)="switchFocus(1, true)"
               (keyup)="switchFocus(1, false)" (paste)="setCopiedValue($event)"
               class="form-control" formControlName="firstDigit">
      </mat-form-field>
      <mat-form-field class="form-group" appearance="outline">
        <input [maxLength]="1" matInput type="text" name="phoneNumber"
               placeholder="_" [almMatInputAutofocus]="focusDigits[1]"
               (focusin)="switchFocus(2, true)"
               (keyup)="switchFocus(2, false)" (paste)="setCopiedValue($event)"
               class="form-control" formControlName="secondDigit">
      </mat-form-field>
      <mat-form-field class="form-group" appearance="outline">
        <input [maxLength]="1" matInput type="text" name="phoneNumber"
               placeholder="_" [almMatInputAutofocus]="focusDigits[2]"
               (focusin)="switchFocus(3, true)"
               (keyup)="switchFocus(3, false)" (paste)="setCopiedValue($event)"
               class="form-control" formControlName="thirdDigit">
      </mat-form-field>
      <mat-form-field class="form-group" appearance="outline">
        <input [maxLength]="1" matInput type="text" name="phoneNumber"
               placeholder="_" [almMatInputAutofocus]="focusDigits[3]"
               (focusin)="switchFocus(4, true)"
               (keyup)="switchFocus(4, false)" (paste)="setCopiedValue($event)"
               class="form-control" formControlName="fourthDigit">
      </mat-form-field>
    </div>
    <div class="otp-input_errors-container">
      <mat-error class="otp-input_errors" *ngIf="getFormFieldsError([
        optInputForm.controls.firstDigit, optInputForm.controls.secondDigit,
        optInputForm.controls.thirdDigit, optInputForm.controls.fourthDigit]) as message">
        {{'opt_input.errors' | translate | translateCut: message - 1}}
      </mat-error>
    </div>
  </form>
</div>
