import {ICourse} from '@interfaces/course/course.interface';
import {EPaymentItemType} from '@interfaces/payment/payment.interface';
import {ISubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {PaymentMethods} from "@interfaces/common/payment.interface";
import {ELoginTypes} from '@interfaces/authorized-user/user.interface';

export interface IFacebookLoginInfoResponse {
  id: string;
  email?: string;
  name: string;
  profileImage: string;
}

export interface ILoginResponse {
  email: string;
  platformName: string;
  userName: string;
  uuid: string;
  loginCountDown: number;
  hasDemographicData?: boolean;
  hasInterests?: boolean;
}

export interface IGoogleLoginResponse {
  uuid:             string;
  userName:         string;
  email:            string;
  platformName:     string;
  isLoginFirstTime: boolean;
  registrationDate: string;
  lastLoginDate:    string | null;
  organizationName?: string;
}

export interface IFacebookLoginResponse {
  uuid:             string;
  userName:         string;
  email:            string;
  platformName:     string;
  isLoginFirstTime: boolean;
  registrationDate: string;
  lastLoginDate:    string | null;
  organizationName?: string;
}

export interface ISignupResponse {
  id:               number;
  uuid:             string;
  registrationDate: string;
}

export interface IActivationResponse {
  email: string;
  userName: string;
  uuid: string;
  platformCodes: string[];
  activationDate: string;
}

export interface ISignupData {
  email?:      string;
  userName:   string;
  uuid:       string;
}

export interface IUserCredentials {
  email: string,
  password: string,
}

export interface PhoneAuthRequest {
  phoneNumber: string;
  countryCode: string;
}

export interface PhoneOTPVerificationRequest {
  phoneNumber: string;
  countryCode: string;
  "otp": string;
}

export interface EmailOTPVerificationRequest {
  email: string;
  "otp": string;
}

export type OTPVerificationRequest = PhoneOTPVerificationRequest | EmailOTPVerificationRequest

export interface PhoneOTPResendRequest {
  phoneNumber: string;
  countryCode: string;
}

export interface EmailOTPResendRequest {
  email: string;
}

export type OTPResendRequest = PhoneOTPResendRequest | EmailOTPResendRequest

export interface OTPVerificationResponse {
  "userUuid": string;
}

export interface OTPVerificationState {
  userIdentifier: string;
  countryCode: string;
  verificationType: ELoginTypes;
}

export interface INewLoginResponse {
  email: string;
  platformName: string;
  userName: string;
  uuid: string;
  lastLoginDate: string | null;
  organizationName?: string;
}

export interface EmailLoginRequest {
  loginIdentifier: string;
  password: string;
  platform: string
  subDomain: string
}
export interface EmailLoginResponse {
  platformName: string;
  uuid: string;
  jwtToken: string
  refreshToken: string
}

export interface RefreshTokenRequest {
  refreshToken: string;
  userUuid: string;
}
export interface RefreshTokenResponse {
  token: string;
}

export interface IPersonalInfo {
  name?: string,
  gender?: boolean | number,
  birthDate?: string,
  cityId?: number,
  countryId?: number,
  mobileNumber?: string
}

export interface IAccountInfo {
  email: string,
  hasPassword?: boolean,
  mobileNumber?: string
}

export interface ISocialMediaProfiles {
  id: number,
  channelUrl: string
}

export interface IChangePasswordPayload {
  currentPassword: string,
  newPassword: string,
  newPasswordRepeated: string
}

export interface IPurchaseLogItem {
  itemTypeId: EPaymentItemType,
  item: ICourse | ISubscriptionPlan,
  status: StatusId,
  discountItems: {
    discountAmount: number
    discountIdentifier: string,
    discountSourceId: number
  }[],
}

export interface IPurchaseLog {
  orderId: string,
  finalPrice: number,
  originalPrice: number,
  currency: string,
  paymentMethod: PaymentMethods,
  status: StatusId,
  date: string,
  items: IPurchaseLogItem[],
}

export interface IPurchaseLogBundle {
  id: number;
  name: string;
  numberOfCourses: number;
}

export interface ILoyaltyInfo {
  loyal: boolean,
  referralCode: string,
  prizeCode: string,
  referralCount: number,
  inviteesCap: number,
  prizeCap: number
}

export interface IB2bBannerInfo {
  countryID: number,
  currrency: string,
  flatRate: number,
  orgName: string,
  userCap: number
}

export enum StatusId {
  INITIATED = 1,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
  EXPIRED,
  CANCELED,
  REFUNDED
}


export interface IUserPlatform {
  domain: string,
  platform: string
}

export interface IUserPlatformDetails {
  platform: string;
  domain: string;
  name?: string;
  image?: string;
  isB2B: boolean;
}

export interface IUserPlatformRoute {
  platform: IUserPlatform,
  path?: string
}

export interface IEnvironmentPlatforms {
  link: string;
  code?: string;
}

export enum EPrevError {
  LoggedOut = '000',
  NoAccess = '001',
  Blocked = '002'
}
