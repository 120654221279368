export interface IPaymentStatuDetails {
  amount: number;
  currency: string;
  seatsNumber: number;
  status: EPaymentStatusId;
}

export enum EPaymentStatusId {
  // we set status here as appear in the database
  CANCELED = 0,
  INITIATED,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
  EXPIRED,
  CANCELED_2ndID,
  REFUNDED,
  PENDING_FAWRY,
  NO_STATUS = 10,
  NOT_COMPLETED,
  DECLINED,
  PENDING,
  CARD_EXPIRED
}

export type IPaymentStatus = keyof typeof EPaymentStatusId
