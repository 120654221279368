import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DynamicLoadService} from '@services/dynamic-load/dynamic-load.service';
import {NavigationEnd, Router} from '@angular/router';
import {IPaymentMethod} from '@interfaces/common/payment.interface';
import {timer} from "rxjs";
import {take} from "rxjs/operators";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";
import {TrackingDataService} from "@services/tracking/tracking-data.service";
import {ClientName, IGoogleTagManagerData} from "@services/tracking/tracking.interface";
import {isPlatformServer} from '@angular/common';

const GTM_DELAY_MS: number = 2000;

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private eventQueue: IGoogleTagManagerData<any>[] = []; // An array to hold event data before GTM gets initialized
  private dataLayer?: any[];


  constructor(private dynamicLoad: DynamicLoadService,
              private router: Router,
              private trackingData: TrackingDataService,
              @Inject(PLATFORM_ID) private platformId: any,
              ) {
    this.trackPageView();
    this.trackingData.SuperProperties.subscribe(properties => {
      this.sendCustomEvent('GLOBAL-PROPERTIES', properties);
    });
  }

  loadGtm(id: string): void {
    // delay GTM initialization waiting for plans endpoint if it is gets called
    /*TODO remove the delay once we have dedicated endpoint for obtain the country code
    *  then we can directly get the country code before init
    *  instead of waiting for plans endpoint to get the country code if called
    *  as we don't need to call plans in some pages that doesn't have pricing
    */
    timer(GTM_DELAY_MS).pipe(take(1)).subscribe(() => {
      this.dynamicLoad.externalScript(
        'gtm-script',
        `https://sst.almentor.net/gtm.js?id=${id}&l=dataLayer`
      ).then((loaded) => {
        if (loaded) {
          (<any> window).dataLayer = (<any> window).dataLayer || [];
          this.dataLayer = (<any> window).dataLayer;
          this.dataLayer.push({
            event: 'gtm.js',
            'gtm.start': new Date().getTime(),
            ...this.getTrackingData(),
          });
          // Process all pending events from the queue
          for( let eventData of this.eventQueue) {
            this.sendEvent(eventData);
          }
          this.eventQueue = [];
        }
      });
    });
  }

  sendCustomEvent(eventName: string, options?: Object) {
    const event = {
      eventName,
      eventData: {
        ...options,
        ...this.getTrackingData(),
        clientName: ClientName.GA4Client
      }
    };
    this.sendEvent(event);
  }

  sendServerSideEvent<T>(event: IGoogleTagManagerData<T>) {
    this.sendEvent(event);
  }

  private sendEvent<T>(event: IGoogleTagManagerData<T>) {
    if (isPlatformServer(this.platformId)) return;

    if (this.dataLayer) {
      this.clearEcommerceData();
      this.dataLayer.push({
        event: event.eventName,
        ...event.eventData
      });
      this.clearServerSideEventData();
    } else {
      this.eventQueue.push(event);
    }
  }

  trackPageView(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sendCustomEvent('page', {'pageName': event.url});
      }
    });
  }

  sendLoginEvent(options: { type: 'CLICK' | 'RESPONSE', status: 'success' | 'failed', loginType?: ELoginTypes }) {
    this.sendCustomEvent('LOGIN', options);
  }

  sendSignupEvent(options: { type: 'CLICK' | 'RESPONSE', status: 'success' | 'failed', loginType?: ELoginTypes }) {
    this.sendCustomEvent('SIGN-UP', options);
  }

  sendUserUUID(userUUID: string | null) {
    this.sendCustomEvent('SET-USER-ID', {userId: userUUID});
  }

  addToCartEvent(options: { from: string, item: { id: number, name: string, price: number, currency: string } }) {
    this.sendCustomEvent('ADD-TO-CART', {
      ecommerce: {
        currencyCode: options.item.currency,
        add: {
          products: [{
            id: options.item.id,
            name: options.item.name,
            list_name: options.from,
            quantity: 1,
            price: options.item.price,
          }]
        }
      }
    });
  }

  removeFromCartEvent(options: { item: { id: number, name: string, price: number, currency: string } }) {
    this.sendCustomEvent('REMOVE-FROM-CART', {
      ecommerce: {
        currencyCode: options.item.currency,
        remove: {
          products: [{
            id: options.item.id,
            name: options.item.name,
            quantity: 1,
            price: options.item.price,
          }]
        }
      }
    });
  }

  addToSaveListEvent(options: { from: string, item: { id: number, name: string } }) {
    this.sendCustomEvent('ADD-TO-SAVE-LIST', options);
  }

  removeFromSaveListEvent(options: { item: { id: number, name: string } }) {
    this.sendCustomEvent('REMOVE-FROM-SAVE-LIST', options);
  }

  subscriptionCheckOutEvent(options: {
    method: IPaymentMethod,
    item: { id: number | string, name: string, price: number, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('CHECKOUT-SUBSCRIPTION', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      ecommerce: {
        checkout: {
          actionField: {step: 1, options: options.method},
          products: items
        }
      }
    });
  }

  successfulPaymentStatus(options: {
    trn: string,
    price: number,
    currency: string
    discount: number,
    item: { id: string, name: string, coupon?: string },
    method: IPaymentMethod
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-SUCCESS', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Success Subscription',
      action: options.trn,
      label: options.item.name,
      value: options.price,
      // Google ecommerce details
      ecommerce: {
        transaction_id: options.trn,
        value: options.price,
        currency: "USD",
        coupon: options.item.coupon,
        items: [{
          item_id: options.item.id,
          item_name: options.item.name,
          price: options.price,
          coupon: options.item.coupon,
          discount: options.discount,
        }]
      }
    });
  }

  pendingPaymentStatus(options: {
    method: IPaymentMethod,
    trn: string
    item: { id: string, name: string, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-PENDING', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Pending Subscription',
      action: options.method,
      label: options.trn
    });
  }

  failedPaymentStatus(options: {
    method: IPaymentMethod,
    trn: string
    item: { id: string, name: string, coupon?: string }
  }) {
    let items: any[] = this.prepareCheckoutItems(options.item);
    this.sendCustomEvent('PAYMENT-STATUS-SUBSCRIPTION-FAILED', {
      paymentMethod: options.method,
      subscriptionPlan: options.item.name,
      couponCode: options.item.coupon,
      category: 'Failed Subscriptions',
      action: options.method,
      label: options.trn
    });
  }

  goToSubscriptionPageEvent(options: { from: string, type: 'HOT' | 'COLD' }) {
    this.sendCustomEvent('GO-SUBSCRIPTION', options);
  }

  subscriptionStatusChangeEvent(options: { type: 'CHANGE' | 'CANCEL', oldPlan: number, newPlan: number }) {
    this.sendCustomEvent('SUBSCRIPTION-CHANGE', options);
  }

  courseBehavioursEvent(options: { type: 'PROMO' | 'INTRO' | 'SHARE', item: { id: number, name: string } }) {
    this.sendCustomEvent(`COURSE-${options.type}`, options.item);
  }

  private prepareCheckoutItems(item: { id: number | string, name: string, price?: number, coupon?: string }): any {
    let items: any[] = [];
    items.push({
      id: item.id,
      name: item.name,
      list_name: 'checkout-subscription',
      quantity: 1,
      price: item.price,
      coupon: item.coupon ? item.coupon : ''
    });
    return items;
  }

  private getTrackingData() {
    const {th_capi_fn, th_capi_em, user_country} = this.trackingData.SuperProperties.getValue();
    return {th_capi_fn, th_capi_em, user_country};
  }

  private clearEcommerceData() {
    this.dataLayer.push(function () {
      this.set('ecommerce', null);
    });
  }
  private clearServerSideEventData() {
    this.dataLayer.push(function () {
      this.set('clientName', null);
      this.set('event_properties', null);
      this.set('user_properties', null);
      this.set('metadata', null);
    });
  }
}
